@import './src/Language/Resources/scss/color.scss';

#language-switch{
    > .form-label{
        display: none;
    }
    
    .choices{
        color:$primary-color;

        .choices__list > div.choices__item{
            word-break: normal;
            display: flex;
            gap: 10px;

            &:before{
                content: '';
                display: inline-block;
                width: 24px;
                height: 14px;
                background-image: url(/build/language/img/flags.svg);
                background-repeat: no-repeat;
                background-size:500px;
                margin-right:3px;
                position:relative;
                top:2px;
                background-position:9999px;
            }

            &[data-value='nl']:before{
                background-position:-183px -108px;
            }

            &[data-value='en']:before{
                background-position:-219px -80px;
            }
        }

        .choices__list--dropdown, .choices__list[aria-expanded]{
            position: relative;
        }

        &[data-type*=select-one]::after{
            top:20px;
        }

        .choices__list--dropdown,
            .choices .choices__list[aria-expanded],
            .choices .choices__inner{
            width:100%;
            max-width:auto;
            top: 0px;
        }

        .choices__list--dropdown .choices__item--selectable::after,
            .choices__list[aria-expanded] .choices__item--selectable::after{
            position:relative;
            top:8px;
            right:0px;	
        }

        .choices__list--dropdown .choices__item--selectable, 
            .choices__list[aria-expanded] .choices__item--selectable{
            padding-right:10px;
        }
    }
}